<template>
    <div class="container">
        <template v-if="!saveSuccess">
            <div class="title-container">
                <button class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
                <h1>{{ $t('timerSettingsTitle') }}</h1>
            </div>
            <p>{{ $t('customizeTimerSettings') }}</p>
            <div class="settings-wrapper">
                <div class="timer-card" v-for="(timer, index) in timers" :key="index">
                    <h2>{{ timer.name }}</h2>
                    <div class="setting" v-for="(setting, settingIndex) in timer.settings" :key="settingIndex">
                        <div v-if="setting.type === 'dropdown'">
                            <label :for="setting.name">{{ translateAndCapitalize(setting.label) }}</label>
                            <select :id="setting.name" v-model="setting.current">
                                <option v-for="option in setting.options" :value="option.value" :key="option.value">
                                    {{ translateAndCapitalize(option.label) }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <button class="button" :disabled="isSaving" @click="saveAlarms">
                {{ isSaving ? $t('savingButton') : $t('saveButton') }}
            </button>
        </template>
        <template v-else>
            <h1>{{ $t('settingsSavedTitle') }}</h1>
            <p>{{ $t('settingsSavedDescription') }}</p>
            <button class="button" @click="goHome">{{ $t('homeButton') }}</button>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isSaving: false,
            saveSuccess: false,
            timers: [] // Initialize timers as an empty array
        };
    },
    created() {
        this.initializeTimers();
    },
    methods: {
        initializeTimers() {
            // Convert the alarms object into an array of values
            const alarms = Object.keys(this.$store.state.authUser.alarms).map(key => ({
                id: key,
                ...this.$store.state.authUser.alarms[key]
            }));

            // Create a timer card for each alarm
            this.timers = alarms.map((alarm) => {
                return {
                    name: `Alarm ${alarm.id}`,
                    settings: [
                        {
                            type: 'dropdown',
                            name: 'enable',
                            label: 'Enable',
                            current: alarm.enabled ? 'On' : 'Off',
                            options: [
                                { label: 'On', value: 'On' },
                                { label: 'Off', value: 'Off' }
                            ]
                        },
                        {
                            type: 'dropdown',
                            name: 'hour',
                            label: 'Hour',
                            current: alarm.hour.toString(),
                            options: Array.from({ length: 24 }, (_, i) => ({
                                label: `${i}:00`,
                                value: i.toString(),
                            }))
                        },
                        {
                            type: 'dropdown',
                            name: 'minute',
                            label: 'Minute',
                            current: alarm.minute.toString(),
                            options: Array.from({ length: 60 }, (_, i) => ({
                                label: `${i}`,
                                value: i.toString(),
                            }))
                        },
                        {
                            type: 'dropdown',
                            name: 'soundName',
                            label: 'Sound',
                            current: alarm.soundName,
                            options: [
                                { label: 'churchbell', value: 'churchbell' },
                                { label: 'bowl', value: 'bowl' },
                                { label: 'cuckoo', value: 'cuckoo' },
                                { label: 'rooster', value: 'rooster' },
                                { label: 'futuristic', value: 'futuristic' }
                            ]
                        },
                        {
                            type: 'dropdown',
                            name: 'volume',
                            label: 'Sound Volume',
                            current: alarm.volume.toString(),
                            options: Array.from({ length: 10 }, (_, i) => ({
                                label: `${(i + 1) * 10}%`,
                                value: `${(i + 1) * 10}`,
                            }))
                        },
                        {
                            type: 'dropdown',
                            name: 'daysOfWeek',
                            label: 'Days',
                            current: this.getDayLabel(alarm.daysOfWeek),
                            options: [
                                { label: 'Monday', value: 'Monday' },
                                { label: 'Tuesday', value: 'Tuesday' },
                                { label: 'Wednesday', value: 'Wednesday' },
                                { label: 'Thursday', value: 'Thursday' },
                                { label: 'Friday', value: 'Friday' },
                                { label: 'Saturday', value: 'Saturday' },
                                { label: 'Sunday', value: 'Sunday' },
                                { label: 'Weekdays', value: 'Weekdays' },
                                { label: 'Weekend', value: 'Weekend' },
                                { label: 'Every Day', value: 'Every Day' }
                            ]
                        }
                    ]
                };
            });
        },
        getDayLabel(daysArray) {
            const dayOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            let selectedDaysCount = daysArray.filter(day => day === 1).length;

            if (selectedDaysCount === 5 && daysArray[0] && daysArray[1] && daysArray[2] && daysArray[3] && daysArray[4] && !daysArray[5] && !daysArray[6]) {
                return "Weekdays";
            } else if (selectedDaysCount === 2 && daysArray[5] && daysArray[6]) {
                return "Weekend";
            } else if (selectedDaysCount === 7) {
                return "Every Day";
            } else if (selectedDaysCount === 1) {
                return dayOptions[daysArray.indexOf(1)];
            } else {
                return daysArray.map((day, index) => (day === 1 ? dayOptions[index] : '')).filter(Boolean).join(', ');
            }
        },
        async connect() {
            // The connect method you provided
            const clockName = this.$store.state.authUser.name;
            this.isLoading = true;
            // console.log("Connecting to Cosmo Clock");
            try {
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/login?identifier=${clockName}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to connect. Please try again.');
                }
                const data = await response.json();
                // console.log("Connected successfully");
                this.connectedCosmo = data; // Store the received Cosmo document
                this.$store.dispatch('authenticateUser', data);
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoading = false; // Reset loading state
            }
        },
        async saveAlarms() {
            this.isSaving = true;
            try {
                const updatedAlarms = {};

                this.timers.forEach((timer, index) => {
                    const alarmIndex = index + 1; // Start with index 1

                    const alarm = {
                        enabled: timer.settings.find(setting => setting.name === 'enable').current === 'On',
                        hour: parseInt(timer.settings.find(setting => setting.name === 'hour').current),
                        minute: parseInt(timer.settings.find(setting => setting.name === 'minute').current),
                        soundName: timer.settings.find(setting => setting.name === 'soundName').current,
                        volume: parseInt(timer.settings.find(setting => setting.name === 'volume').current),
                        daysOfWeek: this.getDaysArray(timer.settings.find(setting => setting.name === 'daysOfWeek').current).map(day => day ? 1 : 0)
                    };

                    updatedAlarms[alarmIndex] = alarm; // Use alarmIndex as the key
                });

                // Prepare the request body
                const requestBody = {
                    clockName: this.$store.state.authUser.name,
                    alarms: updatedAlarms
                };

                // Send the updated alarms to the backend
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/saveAlarms`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });

                if (!response.ok) {
                    throw new Error('Failed to save alarms. Please try again.');
                }

                // Handle the response data
                await response.json();
                // console.log('Alarms saved successfully');

                // Update the store with the new alarms
                await this.connect();
                this.saveSuccess = true;
            } catch (error) {
                console.error('Error saving alarms:', error);
                alert(error.message); // Show error message
            } finally {
                this.isSaving = false;
            }
        },
        getDaysArray(dayLabel) {
            const daysArray = Array(7).fill(false);
            if (dayLabel === 'Weekdays') {
                daysArray.fill(true, 0, 5);
            } else if (dayLabel === 'Weekend') {
                daysArray[5] = true;
                daysArray[6] = true;
            } else if (dayLabel === 'Every Day') {
                daysArray.fill(true, 0, 7);
            } else {
                const dayOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
                const index = dayOptions.indexOf(dayLabel);
                if (index !== -1) {
                    daysArray[index] = true;
                }
            }
            return daysArray;
        },
        translateAndCapitalize(key) {
            let translation = this.$t(key);
            if (translation === key) {
                translation = key;
            }
            return translation.toLowerCase().replace(/(?:^|\s|-)\S/g, a => a.toUpperCase());
        },
        goBack() {
            this.$router.go(-1);
        },
        goHome() {
            this.$router.push({ name: 'CosmoHome' });
        },
    },
};
</script>

<style scoped>
.delete-confirmation {
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-top: 20px;
}

.confirm-button {
    margin-right: 10px;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.settings-wrapper {
    margin: 30px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timer-card {
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.setting {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    width: 80%;
    gap: 10px;
}

.setting>div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* Creates space between the label and the toggle */
}

.setting select {
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    -webkit-appearance: none;
    appearance: none;
}

.setting option {
    background-color: #262626;
    color: white;
}

.button {
    margin-top: 10px;
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
