<template>
    <div class="container">
        <div class="header">
            <button v-if="!confirmationShown" class="back-button" @click="goBack"><i
                    class="fas fa-arrow-left"></i></button>
            <h1>{{ confirmationShown ? $t('scheduleConfirmedTitle') : $t('scheduleAppsTitle') }}</h1>
        </div>
        <p>{{ confirmationShown ? $t('scheduleConfirmed') : $t('chooseAndScheduleApps') }}</p>
        <button v-if="confirmationShown" class="button" @click="goHome">{{ $t('homeButton') }}</button>
        <ul v-if="!confirmationShown" class="app-list">
            <li v-for="(app, index) in apps" :key="index" class="app-row" :class="{ 'selected-card': app.selected }">
                <div @click="toggleAppSelection(app)" class="app-info">
                    <i :class="`fas ${app.icon}`" class="app-icon"></i>
                    <h3>{{ app.name }}</h3>
                </div>
                <div class="time-selection" v-if="app.selected">
                    <div class="time-row">
                        <span>{{ $t('start') }}</span>
                        <select class="dropdown" v-model="app.startTime" :disabled="isFirstSelected(app)">
                            <option v-for="hour in hours" :key="`start-${hour}`" :value="formatHour(hour)">
                                {{ formatHour(hour) }}
                            </option>
                        </select>
                    </div>
                    <div class="time-row">
                        <span>{{ $t('end') }}</span>
                        <select class="dropdown" v-model="app.endTime">
                            <option v-for="hour in hours" :key="`end-${hour}`" :value="formatHour(hour)">
                                {{ formatHour(hour) }}
                            </option>
                        </select>
                    </div>
                </div>
            </li>
        </ul>
        <button v-if="selectedApps.length > 0 && !confirmationShown" class="button" @click="confirmSelection">{{
            $t('confirmButton') }}</button>
    </div>
</template>



<script>
export default {
    data() {
        return {
            // Removed hardcoded apps array
            selectedApps: [],
            selectedInterval: 5, // Default interval
            confirmationShown: false,
        };
    },
    computed: {
        // Access apps from the Vuex store
        apps() {
            const appTranslationKeys = {
                1: 'appNameClock',
                2: 'appNameTempIn',
                3: 'appNameTempOut',
                4: 'appNameTimeMoon',
                5: 'appNameTimeHoro',
                6: 'appNameTimeStocks',
                7: 'appNameWeather',
                8: 'appNameForecast',
                9: 'appNameMoon',
                10: 'appNameHoroscope',
                11: 'appNameStocks',
                // Add more mappings as needed
            };

            return this.$store.state.authUser.apps.map(app => {
                // Check if the app ID has a corresponding translation key
                const translationKey = appTranslationKeys[app.id];

                return {
                    ...app,
                    // If a translation key exists, use it. Otherwise, fallback to the app's name or ID.
                    name: translationKey ? this.$t(translationKey) : app.name || `App ${app.id}`
                };
            });

        },
        hours() {
            return Array.from({ length: 24 }, (_, i) => i);
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        toggleAppSelection(app) {
            if (app.selected && this.selectedApps.length === 5) {
                app.selected = false;
                this.selectedApps = this.apps.filter(app => app.selected);
            } else if (this.selectedApps.length < 5) {
                if (!app.selected && this.selectedApps.length === 0) {
                    app.selected = true;
                    app.startTime = '00:00'; // Enforce midnight start time for the first selected app
                } else {
                    app.selected = !app.selected; // Toggle selection
                }
                this.selectedApps = this.apps.filter(app => app.selected);
            } else {
                alert('You can only select up to 5 apps');
            }
        },
        isFirstSelected(app) {
            // console.log(this.selectedApps[0])
            if (this.selectedApps.length > 0 && this.selectedApps[0].name === app.name) {
                // console.log("First selected app:", app.name, "Start Time:", app.startTime);
                return app.startTime === '00:00';
            }
            return false;
        },
        confirmSelection() {
            const timeToMinutes = (time) => {
                const [hours, minutes] = time.split(':').map(Number);
                return hours * 60 + minutes;
            };

            // Assuming a full day is 24 * 60 minutes = 1440 minutes
            let coveredMinutes = new Array(1440).fill(false);

            for (const app of this.selectedApps) {
                let startTimeInMinutes = timeToMinutes(app.startTime);
                let endTimeInMinutes = timeToMinutes(app.endTime);

                // Handle the wrap-around case (e.g., 23:00-02:00)
                if (endTimeInMinutes <= startTimeInMinutes) {
                    endTimeInMinutes += 1440; // Add a full day to the end time for overnight schedules
                }

                for (let minute = startTimeInMinutes; minute < endTimeInMinutes; minute++) {
                    if (minute < 1440) {
                        // Mark each minute of the day as covered
                        coveredMinutes[minute] = true;
                    } else {
                        // For minutes beyond a full day, wrap around to start marking from 0 again
                        coveredMinutes[minute - 1440] = true;
                    }
                }
            }

            // Check if there are any false (uncovered minutes) left
            const fullDayCovered = coveredMinutes.every(covered => covered);

            if (!fullDayCovered) {
                alert('There is an overlap or a gap in your schedule.');
                return; // Exit the method early
            }

            // If the day is fully covered without overlaps, proceed with submission
            if (fullDayCovered) {
                const postData = {
                    clockName: this.$store.state.authUser.name,
                    appSchedules: this.selectedApps.map(app => ({
                        appId: app.id,
                        startHour: parseInt(app.startTime.split(':')[0]),
                        endHour: parseInt(app.endTime.split(':')[0]),
                    })),
                    updateInterval: parseInt(this.selectedInterval),
                };

                const url = `${process.env.VUE_APP_API_BASE_URL}/scheduleApps`;
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                })
                    .then(response => {
                        if (!response.ok) {
                            alert('Connection error, please try again.');
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(() => {
                        this.confirmationShown = true;
                    })
                    .catch(error => {
                        console.error('Error updating app display settings:', error);
                    });
            }

        },
        goHome() {
            this.$router.push({ name: 'CosmoHome' });
        },
        formatHour(hour) {
            return `${hour.toString().padStart(2, '0')}:00`;
        }
    },
};
</script>

<style scoped>
.app-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 20px -10px;
}

.app-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* Align items to the top */
    align-items: center;
    background: #333;
    border-radius: 20px;
    padding: 20px;
    width: calc(50% - 20px);
    margin: 10px;
    box-sizing: border-box;
    border: 1px solid transparent;
    height: 200px;
    /* Set a fixed height to accommodate dropdowns */
}

.app-info {
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    /* Stack icon and text vertically */
    align-items: center;
    justify-content: center;
    /* Center vertically in the available space */
    flex-grow: 1;
    /* Allow app-info to take up available space */
}

.app-icon {
    font-size: 30px;
}

.time-selection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    /* Hide dropdowns by default */
    flex-grow: 0;
    /* Prevent time-selection from taking up space when hidden */
    transition: max-height 0.3s ease;
}

.app-row.selected-card .app-info {
    justify-content: flex-start;
    /* Align to top when card is selected */
}

.app-row.selected-card .time-selection {
    visibility: visible;
    flex-grow: 1;
    /* Allow time-selection to expand and take up space when visible */
}

.time-row {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    margin-top: 5px;
}

.time-row span {
    width: 40px;
    display: inline-block;
    text-align: left;
}

.dropdown {
    width: calc(100% - 50px);
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    -webkit-appearance: none;
    appearance: none;
}

.dropdown:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.container option {
    background-color: #262626;
    color: white;
}

.selected-card {
    border-color: white;
    /* Highlight selected apps */
}
</style>
