<template>
    <div class="container">
        <template v-if="!saveSuccess">
            <div class="title-container">
                <button class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
                <h1>{{ $t('appSettingsTitle') }}</h1>
            </div>
            <p>{{ $t('customizeAppSettings', { appName: appName }) }}</p>
            <div class="settings-wrapper">
                <div class="setting" v-for="(setting, index) in settings" :key="index">
                    <div v-if="setting.type === 'dropdown'">
                        <label :for="setting.name">{{ translateAndCapitalize(setting.label) }}</label>
                        <select :id="setting.name" v-model="setting.current">
                            <option v-for="option in setting.options" :value="option.value" :key="option.value">
                                {{ translateAndCapitalize(option.label) }}
                            </option>
                        </select>
                        <!-- Dynamic hints or details can be internationalized similarly if needed -->
                    </div>
                    <!-- Additional setting types can be handled here -->
                </div>
            </div>
            <button v-if="appIsCustom" class="button delete-button" @click="confirmDeleteApp">
                {{ $t('deleteAppButton') }}
            </button>

            <div v-if="showDeleteConfirmation" class="delete-confirmation">
                <p>{{ $t('confirmDeleteAppMessage', { appName: appName }) }}</p>
                <button class="button confirm-button" @click="deleteApp">{{ $t('confirmDeleteButton') }}</button>
                <button class="button cancel-button" @click="cancelDelete">{{ $t('cancelButton') }}</button>
            </div>

            <button class="button" :disabled="isSaving" @click="saveSettings">
                {{ isSaving ? $t('savingButton') : $t('saveButton') }}
            </button>
        </template>
        <template v-else>
            <h1>{{ $t('settingsSavedTitle') }}</h1>
            <p>{{ $t('settingsSavedDescription') }}</p>
            <button class="button" @click="goHome">{{ $t('homeButton') }}</button>
        </template>
    </div>
</template>


<script>

export default {
    data() {
        return {
            isSaving: false,
            saveSuccess: false,
            settings: [], // Initialize settings as an empty array
            appName: '', // Added to store the name of the selected app
            appIsCustom: false, // Add this to track if the app is custom
            showDeleteConfirmation: false, // Track if the delete confirmation is visible
        };
    },
    created() {
        const appName = this.$route.query.name; // Get app name from route query
        this.appName = appName; // Store app name in component data
        const app = this.$store.state.authUser.apps.find(app => app.name === appName);
        this.appIsCustom = app?.customApp || false; // Check if the app is custom
        this.initializeSettings(appName); // Pass app name to initializeSettings method
    },
    methods: {
        confirmDeleteApp() {
            this.showDeleteConfirmation = true;
        },
        cancelDelete() {
            this.showDeleteConfirmation = false;
        },
        async deleteApp() {
            try {
                const app = this.$store.state.authUser.apps.find(app => app.name === this.appName);
                if (!app) {
                    throw new Error('App not found');
                }
                // console.log('clockId:', this.$store.state.authUser.clockId, 'appId:', app.id);

                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/deleteCustomApp`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        clockId: this.$store.state.authUser.clockId,
                        appId: app.id,
                    }),
                });

                // console.log(response)

                if (!response.ok) {
                    throw new Error('Failed to delete app. Please try again.');
                }

                await this.connect();
                this.$router.push({ name: 'CosmoHome' });
            } catch (error) {
                console.error('Error deleting app:', error);
                alert(error.message);
            }
        },
        translateAndCapitalize(key) {
            // Attempt to get a translation or use the key itself as a fallback
            let translation = this.$t(key);

            // Check if the translation found is the same as the key, indicating no translation exists
            if (translation === key) {
                // If no translation is found, use the key as the default
                translation = key;
            }

            // Make the entire string lowercase then capitalize the first letter of each word
            return translation
                .toLowerCase()
                .replace(/(?:^|\s|-)\S/g, a => a.toUpperCase()); // Capitalize first letters
        },
        initializeSettings(appName) {
            const app = this.$store.state.authUser.apps.find(app => app.name === appName);
            if (!app) {
                console.error('App not found');
                return;
            }

            // Find the app settings for the current app by appId
            const userAppSettings = this.$store.state.authUser.appSettings?.find(setting => setting.appId === app.id);

            let settingsDefinitions = [];

            // Update Interval
            if (app.updateInterval !== undefined && app.availableUpdateIntervals) {
                let updateIntervalCurrent = app.updateInterval.toString(); // Default to app's updateInterval
                if (userAppSettings && userAppSettings.updateInterval !== undefined) {
                    updateIntervalCurrent = userAppSettings.updateInterval.toString(); // Override if user has a customized setting
                }
                if (app.updateInterval !== undefined && app.availableUpdateIntervals) {
                    settingsDefinitions.push({
                        type: 'dropdown',
                        name: 'updateInterval',
                        label: 'Update Interval',
                        current: updateIntervalCurrent,
                        options: app.availableUpdateIntervals.map(interval => ({
                            label: interval + ' minutes',
                            value: interval.toString()
                        }))
                    });
                }
            }

            // Global Variable
            if (app.selectedGlobalVariableName && app.selectedGlobalVariable && app.availableGlobalVariables) {
                let globalVariableCurrent = app.selectedGlobalVariable; // Default to app's selectedGlobalVariable
                if (userAppSettings && userAppSettings.selectedGlobalVariableName !== undefined && userAppSettings.selectedGlobalVariableName === app.selectedGlobalVariableName && userAppSettings.selectedGlobalVariable !== undefined) {
                    globalVariableCurrent = userAppSettings.selectedGlobalVariable; // Override if user has a customized setting
                }
                if (app.selectedGlobalVariableName && app.selectedGlobalVariable && app.availableGlobalVariables) {
                    settingsDefinitions.push({
                        type: 'dropdown',
                        name: app.selectedGlobalVariableName,
                        label: app.selectedGlobalVariableName,
                        current: globalVariableCurrent,
                        options: app.availableGlobalVariables.map(variable => ({
                            label: variable,
                            value: variable
                        }))
                    });
                }
            }
            settingsDefinitions.push(
                {
                    type: 'dropdown',
                    name: 'audioMinutes',
                    label: 'Play Sound Every',
                    current: userAppSettings?.audioMinutes?.toString() || '-1',
                    options: [
                        { label: 'Never', value: '-1' },
                        { label: '15 minutes', value: '15' },
                        { label: '30 minutes', value: '30' },
                        { label: '1 hour', value: '60' },
                    ]
                },
                {
                    type: 'dropdown',
                    name: 'audioHoursFrom',
                    label: 'From Hour',
                    current: userAppSettings?.audioHours?.[0]?.toString() || '0',
                    options: Array.from({ length: 24 }, (_, i) => ({
                        label: `${i}:00`,
                        value: i.toString(),
                    })),
                    hidden: userAppSettings?.audioMinutes?.toString() === '-1'
                },
                {
                    type: 'dropdown',
                    name: 'audioHoursTo',
                    label: 'To Hour',
                    current: userAppSettings?.audioHours?.[userAppSettings.audioHours.length - 1]?.toString() || '23',
                    options: Array.from({ length: 24 }, (_, i) => ({
                        label: `${i}:00`,
                        value: i.toString(),
                    })),
                    hidden: userAppSettings?.audioMinutes?.toString() === '-1'
                },
                {
                    type: 'dropdown',
                    name: 'audioName',
                    label: 'Sound',
                    current: userAppSettings?.audioName || 'churchbell',
                    options: [
                        { label: 'churchbell', value: 'churchbell' },
                        { label: 'bowl', value: 'bowl' },
                        { label: 'cuckoo', value: 'cuckoo' },
                        { label: 'rooster', value: 'rooster' },
                        { label: 'futuristic', value: 'futuristic' }
                    ],
                    hidden: userAppSettings?.audioMinutes?.toString() === '-1'
                },
                {
                    type: 'dropdown',
                    name: 'audioGain',
                    label: 'Sound Volume',
                    current: userAppSettings?.audioGain || '50',
                    options: Array.from({ length: 10 }, (_, i) => ({
                        label: `${(i + 1) * 10}%`,
                        value: `${(i + 1) * 10}`,
                    })),
                }
            );
            // Display Areas
            const seenSettings = new Map();  // Track settings that have already been added
            app.displayAreas.forEach((area) => {
                if (area.selectedVariableName && area.selectedVariable && area.availableVariables) {
                    const key = `${area.position}-${area.selectedVariableName}-${JSON.stringify(area.availableVariables)}`;

                    if (!seenSettings.has(key)) {
                        let currentVariable = area.selectedVariable; // Default to app's selectedVariable
                        // Override if user has a customized setting for the same variable name
                        const userAreaSetting = userAppSettings?.displayAreas?.find(userArea => userArea.selectedVariableName === area.selectedVariableName && userArea.position === area.position);
                        if (userAreaSetting && userAreaSetting.selectedVariable !== undefined) {
                            currentVariable = userAreaSetting.selectedVariable;
                        }

                        settingsDefinitions.push({
                            type: 'dropdown',
                            name: area.selectedVariableName,
                            label: area.selectedVariableName,
                            current: currentVariable,
                            options: area.availableVariables.map(variable => ({
                                label: variable,
                                value: variable
                            })),
                            position: area.position || 0
                        });

                        seenSettings.set(key, true);  // Mark this setting as added
                    }
                }
            });




            this.settings = settingsDefinitions;
        },
        capitalizeWords(label) {
            return label.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
        },
        goBack() {
            this.$router.go(-1);
        },
        showDetails(details) {
            alert(details);
        },
        handleToggle(setting) {
            setting.value = !setting.value;
        },
        async connect() {
            // The connect method you provided
            const clockName = this.$store.state.authUser.name;
            this.isLoading = true;
            // console.log("Connecting to Cosmo Clock");
            try {
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/login?identifier=${clockName}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to connect. Please try again.');
                }
                const data = await response.json();
                // console.log("Connected successfully");
                this.connectedCosmo = data; // Store the received Cosmo document
                this.$store.dispatch('authenticateUser', data);
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoading = false; // Reset loading state
            }
        },
        async saveSettings() {
            this.isSaving = true;

            try {
                const app = this.$store.state.authUser.apps.find(app => app.name === this.appName);
                if (!app) {
                    throw new Error('App not found in store');
                }

                const updatedSettings = [];
                const soundSettings = {};

                // Iterate over each grouped setting and ungroup them
                this.settings.forEach(settingGroup => {
                    if (settingGroup.type === 'dropdown') {
                        // Find the corresponding area in the app's displayAreas based on position and name
                        app.displayAreas.forEach((area) => {
                            if (area.position === settingGroup.position && area.selectedVariableName === settingGroup.name) {
                                updatedSettings.push({
                                    name: area.selectedVariableName,
                                    current: settingGroup.current,
                                    type: settingGroup.type,
                                    position: area.position
                                });
                            }
                        });

                        // Capture sound settings if they exist
                        if (settingGroup.name === 'audioMinutes') {
                            soundSettings.audioMinutes = settingGroup.current;
                        } else if (settingGroup.name === 'audioHoursFrom') {
                            soundSettings.audioHoursFrom = settingGroup.current;
                        } else if (settingGroup.name === 'audioHoursTo') {
                            soundSettings.audioHoursTo = settingGroup.current;
                        } else if (settingGroup.name === 'audioName') {
                            soundSettings.audioName = settingGroup.current;
                        } else if (settingGroup.name === 'audioGain') {
                            soundSettings.audioGain = settingGroup.current;
                        }
                    }
                });

                // Log the updated settings before sending
                // console.log('Ungrouped Settings:', updatedSettings);

                // Prepare the request body
                const requestBody = {
                    clockName: this.$store.state.authUser.name,
                    appId: app.id,
                    settings: updatedSettings,
                    customApp: app.customApp || false,
                    soundSettings: soundSettings
                };

                // Print the request body before sending
                // console.log('Request Body:', requestBody);

                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/saveAppSettings`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });

                if (!response.ok) {
                    throw new Error('Failed to save settings. Please try again.');
                }

                // Optionally, handle the response data
                const data = await response.json();
                // console.log('Settings saved successfully');

                this.$store.commit('updateAppSettings', data.appSettings);
                await this.connect();
                this.saveSuccess = true;
            } catch (error) {
                console.error('Error saving settings:', error);
                alert(error.message); // Show error message
            } finally {
                this.isSaving = false;
            }
        },
        goHome() {
            this.$router.push({ name: 'CosmoHome' });
        },
        navigateTo(destination) {
            this.$router.push(destination);
        },
    },
};
</script>

<style scoped>
.delete-confirmation {
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-top: 20px;
}

.confirm-button {
    margin-right: 10px;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.settings-wrapper {
    margin: 30px 0;
    /* Adjust the top and bottom margin as needed */
    width: 100%;
    /* Ensure it spans the width of its container */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center-align the settings */
}

.setting {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Adjust as necessary */
    margin-bottom: 15px;
    width: 80%;
}

.toggle-wrapper {
    transform: scale(0.8);
    display: inline-block;
}

/* This targets the direct container of the toggle and label, adjust as needed */
.setting>div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* Creates space between the label and the toggle */
}

.question-mark {
    cursor: pointer;
    color: rgb(100, 100, 100);
    /* Ensure the question mark is white */
    margin-left: 5px;
    font-size: 16px;
    /* Adjust the size of the question mark */
    border: 1px solid rgb(104, 104, 104);
    /* Create a border around the question mark */
    border-radius: 50%;
    /* Make the border rounded */
    padding: 2px;
    /* Add some padding around the question mark */
    display: inline-flex;
    /* Use flex to center the content */
    align-items: center;
    /* Align items vertically */
    justify-content: center;
    /* Center content horizontally */
    text-align: center;
    /* Ensure text is centered */
}

.vue-toggle {
    transform: scale(0.1);
    /* Adjust scale as needed to make the toggle smaller */
}

.setting select {
    padding: 5px 10px;
    background-color: transparent;
    /* No background */
    color: white;
    /* Text color */
    border: 1px solid white;
    /* White border */
    border-radius: 5px;
    /* Rounded corners */
    cursor: pointer;
    /* Pointer cursor on hover */
    font-size: 16px;
    /* Text size */
    -webkit-appearance: none;
    /* For some browsers to remove default styling */
    appearance: none;
}

.setting option {
    background-color: #262626;
    /* No background */
    color: white;
    /* Text color */
}

.button {
    margin-top: 10px;
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.detail-button {
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
    /* Creates space between the label and the button */
    transition: background-color 0.3s ease;
}

.detail-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}


.container p {
    margin-bottom: 0px;
}
</style>
