<template>
  <div id="app">
    <!-- Remove or comment out the lines below if you don't want the static content -->
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view/>
  </div>
</template>

<script>
// If you're not using the HelloWorld component elsewhere, you can also comment out or remove this import
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  // And also comment out or remove this if you're not using HelloWorld
  // components: {
  //   HelloWorld
  // }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
