<template>
    <div class="container">
        <div class="header">
            <button v-if="!confirmationShown" class="back-button" @click="goBack"><i
                    class="fas fa-arrow-left"></i></button>
            <h1>{{ confirmationShown ? $t('appsConfirmedTitle') : $t('appRotationTitle') }}</h1>
        </div>
        <p>{{ confirmationShown ? $t('selectionConfirmed') : $t('selectUpTo5Apps') }}</p>
        <button v-if="confirmationShown" class="button" @click="goHome">{{ $t('homeButton') }}</button>
        <div v-if="!confirmationShown" class="app-grid">
            <div v-for="(app, index) in apps" :key="index" @click="toggleAppSelection(app)"
                :class="{ 'app-card': true, 'selected': app.selected }">
                <i :class="`fas ${app.icon}`" class="app-icon"></i>
                <h3>{{ app.name }}</h3>
            </div>
        </div>
        <div v-if="selectedApps.length > 0 && !confirmationShown" class="setup-confirmation">
            <p class="text">{{ $t('setIntervalForAppRotation') }}</p>
            <select v-model="selectedInterval" class="dropdown">
                <option value="10">10 {{ $t('minutesOption', { value: 10 }) }}</option>
                <option value="30">30 {{ $t('minutesOption', { value: 30 }) }}</option>
                <option value="60">60 {{ $t('minutesOption', { value: 60 }) }}</option>
            </select>

        </div>
        <button v-if="selectedApps.length > 0 && !confirmationShown" class="button" @click="confirmSelection">{{
            $t('confirmButton') }}</button>
    </div>
</template>


<script>
export default {
    data() {
        return {
            // Removed hardcoded apps array
            selectedApps: [],
            selectedInterval: 30, // Default interval
            confirmationShown: false,
        };
    },
    computed: {
        apps() {
            const appTranslationKeys = {
                1: 'appNameClock',
                2: 'appNameTempIn',
                3: 'appNameTempOut',
                4: 'appNameTimeMoon',
                5: 'appNameTimeHoro',
                6: 'appNameTimeStocks',
                7: 'appNameWeather',
                8: 'appNameForecast',
                9: 'appNameMoon',
                10: 'appNameHoroscope',
                11: 'appNameStocks',
                // Add more mappings as needed
            };

            return this.$store.state.authUser.apps.map(app => {
                // Check if the app ID has a corresponding translation key
                const translationKey = appTranslationKeys[app.id];

                return {
                    ...app,
                    // If a translation key exists, use it. Otherwise, fallback to the app's name or ID.
                    name: translationKey ? this.$t(translationKey) : app.name || `App ${app.id}`
                };
            });
        }
    },

    methods: {
        goBack() {
            this.$router.go(-1);
        },
        toggleAppSelection(app) {
            // If the app is already selected, allow the user to deselect it
            if (app.selected) {
                app.selected = false;
            } else if (this.selectedApps.length < 5) {
                // If fewer than 5 apps are selected, allow selection
                app.selected = true;
            }
            // Update the selectedApps array based on current selections
            this.selectedApps = this.apps.filter(app => app.selected);
        },

        confirmSelection() {
            const postData = {
                clockName: this.$store.state.authUser.name, // Assuming the clock name is available in the Vuex store
                appIds: this.selectedApps.map(app => app.id),
                updateInterval: parseInt(this.selectedInterval),
            };

            // Update the URL to match your endpoint
            const url = `${process.env.VUE_APP_API_BASE_URL}/displayApps`;

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(() => {
                    this.confirmationShown = true;
                })
                .catch(error => {
                    console.error('Error updating app display settings:', error);
                });
        },
        goHome() {
            this.$router.push({ name: 'CosmoHome' });
        },
    },
};
</script>

<style scoped>
.dropdown {
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    padding: 5px 10px;
    background-color: transparent;
    /* No background */
    color: white;
    /* Text color */
    border: 1px solid white;
    /* White border */
    border-radius: 5px;
    /* Rounded corners */
    cursor: pointer;
    /* Pointer cursor on hover */
    font-size: 16px;
    /* Text size */
    -webkit-appearance: none;
    /* For some browsers to remove default styling */
    appearance: none;

    margin-top: 10px;
    margin-bottom: 10px;
}

.dropdown:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.container option {
    background-color: #262626;
    /* No background */
    color: white;
    /* Text color */
}

.confirmation p {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 20px;
}

.app-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    /* Center the grid */
}

.app-card {
    background: #333;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.app-icon {
    font-size: 30px;
    margin-bottom: 5px;
}

.confirmation {
    text-align: center;
}

.app-card h3 {
    text-align: center;
    margin: 10px 0 0;
    /* Adjust top margin to separate from icon */
}

.button {
    margin-top: 10px;
}

.app-card.selected {
    border: 1px solid #ffffff;
    /* Highlight selected apps */
}

.app-card .order-number {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #4CAF50;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.text {
    margin-bottom: 0px;
    margin-top: 50px;
}

.container p {
    margin-bottom: 10px;
}
</style>