<template>
    <div class="container">
        <template v-if="!timeSetSuccess">
            <div class="title-container">
                <button class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
                <h1>{{ $t('timeDateTitle') }}</h1>
            </div>
            <p>{{ $t('adjustTimeDate') }}</p>
            <div class="settings-wrapper">
                <div class="setting">
                    <label for="time">{{ $t('timeLabel') }}</label>
                    <input type="text" id="time" v-model="time" :placeholder="$t('timePlaceholder')" pattern="\d{2}:\d{2}">
                </div>
                <div class="setting">
                    <label for="date">{{ $t('dateLabel') }}</label>
                    <input type="text" id="date" v-model="date" :placeholder="$t('datePlaceholder')" pattern="\d{2}/\d{2}/\d{4}">
                </div>
            </div>
            <button class="button" :disabled="isSettingTime" @click="setTime">
                {{ isSettingTime ? $t('settingButton') : $t('setTimeDateButton') }}
            </button>
        </template>
        <template v-else>
            <h1>{{ $t('timeDateSetTitle') }}</h1>
            <p>{{ $t('changesApplied') }}</p>
            <button class="button" @click="goHome">{{ $t('homeButton') }}</button>
        </template>
    </div>
</template>


<script>
export default {
    data() {
        return {
            isSettingTime: false,
            timeSetSuccess: false,
            time: '', // Hour:Minute
            date: '', // Day/Month/Year
        };
    },
    created() {
        this.initializeCurrentDateTime();
    },
    methods: {
        initializeCurrentDateTime() {
            const now = new Date();
            const day = now.getDate().toString().padStart(2, '0');
            const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
            const year = now.getFullYear();
            const hour = now.getHours().toString().padStart(2, '0');
            const minute = now.getMinutes().toString().padStart(2, '0');

            this.date = `${day}/${month}/${year}`;
            this.time = `${hour}:${minute}`;
        },
        goBack() {
            this.$router.go(-1);
        },
        async setTime() {
            this.isSettingTime = true;

            // Validate time format HH:MM
            if (!/^\d{2}:\d{2}$/.test(this.time)) {
                alert('Time must be in HH:MM format.');
                this.isSettingTime = false;
                return;
            }

            const [hour, minute] = this.time.split(':').map(Number);
            // Validate hour and minute
            if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
                alert('Hour must be between 00 and 23, and minute between 00 and 59.');
                this.isSettingTime = false;
                return;
            }

            // Validate date format DD/MM/YYYY
            if (!/^\d{2}\/\d{2}\/\d{4}$/.test(this.date)) {
                alert('Date must be in DD/MM/YYYY format.');
                this.isSettingTime = false;
                return;
            }

            const [day, month, year] = this.date.split('/').map(Number);
            // Basic validation for year
            if (year < 1000 || year > 3000) {
                alert('Year must be between 1000 and 3000.');
                this.isSettingTime = false;
                return;
            }

            // Check for valid date
            const dateObj = new Date(year, month - 1, day);
            if (dateObj.getFullYear() !== year || dateObj.getMonth() + 1 !== month || dateObj.getDate() !== day) {
                alert('Invalid date. Please check your input.');
                this.isSettingTime = false;
                return;
            }

            const clockName = this.$store.state.authUser.name

            const payload = { hour, minute, day, month, year, clockName };

            try {
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/setTime`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload),
                });

                if (!response.ok) {
                    throw new Error('Failed to set time and date. Please try again.');
                }

                // console.log('Time and date set successfully');
                this.timeSetSuccess = true;
            } catch (error) {
                console.error('Error setting time and date:', error);
                alert(error.message);
            } finally {
                this.isSettingTime = false;
            }
        },
        goHome() {
            this.$router.push({ name: 'CosmoHome' });
        },
    },
};
</script>


<style scoped>
.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.settings-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.setting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 500px;
    /* Adjust based on your design */
    margin-bottom: 20px;
}

.setting label {
    flex-basis: 30%;
    text-align: right;
    margin-right: 20px;
}

.setting input {
    flex-grow: 1;
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    font-size: 16px;
}

.toggle-wrapper {
    transform: scale(0.8);
    display: inline-block;
}

/* This targets the direct container of the toggle and label, adjust as needed */
.setting>div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* Creates space between the label and the toggle */
}

.question-mark {
    cursor: pointer;
    color: rgb(100, 100, 100);
    /* Ensure the question mark is white */
    margin-left: 5px;
    font-size: 16px;
    /* Adjust the size of the question mark */
    border: 1px solid rgb(104, 104, 104);
    /* Create a border around the question mark */
    border-radius: 50%;
    /* Make the border rounded */
    padding: 2px;
    /* Add some padding around the question mark */
    display: inline-flex;
    /* Use flex to center the content */
    align-items: center;
    /* Align items vertically */
    justify-content: center;
    /* Center content horizontally */
    text-align: center;
    /* Ensure text is centered */
    padding: 0;
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
}

.vue-toggle {
    transform: scale(0.1);
    /* Adjust scale as needed to make the toggle smaller */
}

.setting select {
    padding: 5px 10px;
    background-color: transparent;
    /* No background */
    color: white;
    /* Text color */
    border: 1px solid white;
    /* White border */
    border-radius: 5px;
    /* Rounded corners */
    cursor: pointer;
    /* Pointer cursor on hover */
    font-size: 16px;
    /* Text size */
    -webkit-appearance: none;
    /* For some browsers to remove default styling */
    appearance: none;
}

.setting option {
    background-color: #262626;
    /* No background */
    color: white;
    /* Text color */
}

.button {
    margin-top: 10px;
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.detail-button {
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
    /* Creates space between the label and the button */
    transition: background-color 0.3s ease;
}

.detail-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}


.container p {
    margin-bottom: 30px;
}

.setting input {
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    margin-left: 10px;
    font-size: 16px;
}

.datetime-setting {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datetime-setting label {
    margin-right: 10px;
}
</style>