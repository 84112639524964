<template>
    <div class="container">
        <div class="header">
            <button v-if="!confirmationShown" class="back-button" @click="goBack"><i
                    class="fas fa-arrow-left"></i></button>
            <h1>{{ confirmationShown ? $t('appsConfirmedTitle') : $t('appRotationTitle') }}</h1>
        </div>
        <p>{{ confirmationShown ? $t('selectionConfirmed') : $t('selectUpTo10Apps') }}</p>
        <button v-if="confirmationShown" class="button" @click="goHome">{{ $t('homeButton') }}</button>

        <div v-if="!confirmationShown" class="v3-app-grid">
            <div v-for="(app, index) in apps" :key="index" class="v3-app-container" @click="toggleAppSelection(app)">
                <div :class="['v3-app-card', { 'selected': app.selected }]">
                    <p>{{ app.name }}</p>
                    <div class="frame-container">
                        <div class="frame small-frame">
                            <div v-for="position in [0, 1, 2]" :key="position" :class="[
                                'frame-row',
                                { 'double-row': getCardByPosition(app, position)?.size === 2 },
                                { 'no-row': position > 0 && getCardByPosition(app, position - 1)?.size === 2 }
                            ]">
                                <!-- Check if the card exists for this position -->
                                <img v-if="!getCardByPosition(app, position) && position > 0 && getCardByPosition(app, position - 1)?.size !== 2"
                                    :src="getCardImage({ imagePath: 'empty.png' })"
                                    class="frame-image small-frame-image" alt="Placeholder Image">
                                <img v-else-if="getCardByPosition(app, position)"
                                    :src="getCardImage(getCardByPosition(app, position))"
                                    class="frame-image small-frame-image"
                                    :class="{ 'double-image': getCardByPosition(app, position)?.size === 2 }"
                                    alt="Card Image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="selectedApps.length > 0 && !confirmationShown" class="setup-confirmation">
            <p class="text">{{ $t('setIntervalForAppRotation') }}</p>
            <select v-model="selectedInterval" class="dropdown">
                <option value="5">5 {{ $t('minutesOption', { value: 5 }) }}</option>
                <option value="10">10 {{ $t('minutesOption', { value: 10 }) }}</option>
                <option value="15">15 {{ $t('minutesOption', { value: 15 }) }}</option>
                <option value="30">30 {{ $t('minutesOption', { value: 30 }) }}</option>
                <option value="60">60 {{ $t('minutesOption', { value: 60 }) }}</option>
            </select>
        </div>

        <button v-if="selectedApps.length > 0 && !confirmationShown" class="button" @click="confirmSelection">
            {{ $t('confirmButton') }}
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedApps: [],
            selectedInterval: 30, // Default interval
            confirmationShown: false,
        };
    },
    computed: {
        apps() {
            const appTranslationKeys = {
                1: 'appNameClock',
                2: 'appNameTempIn',
                3: 'appNameTempOut',
                4: 'appNameTimeMoon',
                5: 'appNameTimeHoro',
                6: 'appNameTimeStocks',
                7: 'appNameWeather',
                8: 'appNameForecast',
                9: 'appNameMoon',
                10: 'appNameHoroscope',
                11: 'appNameStocks',
                // Add more mappings as needed
            };

            return this.$store.state.authUser.apps.map(app => {
                return {
                    ...app,
                    cards: app.customApp
                        ? this.$store.state.authUser.customApps.find(customApp => customApp.clockJson.id === app.id)?.cards
                        : this.$store.state.authUser.defaultApps.find(defaultApp => defaultApp.clockJson.id === app.id)?.cards,
                    name: appTranslationKeys[app.id] ? this.$t(appTranslationKeys[app.id]) : app.name || `App ${app.id}`,
                };
            });
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        toggleAppSelection(app) {
            if (app.selected) {
                app.selected = false;
            } else if (this.selectedApps.length < 10) {
                app.selected = true;
            }
            this.selectedApps = this.apps.filter(app => app.selected);
        },
        confirmSelection() {
            const postData = {
                clockId: this.$store.state.authUser.clockId,
                appIds: this.selectedApps.map(app => app.id),
                updateInterval: parseInt(this.selectedInterval),
            };

            // console.log(postData)

            const url = `${process.env.VUE_APP_API_BASE_URL}/setRotateApps`;

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(() => {
                    this.confirmationShown = true;
                })
                .catch(error => {
                    console.error('Error updating app display settings:', error);
                });
        },
        getCardImage(card) {
            if (!card || !card.imagePath) {
                return `/images/displayCards/empty.png`;
            }
            try {
                return `/images/displayCards/${card.imagePath}`;
            } catch (error) {
                console.error('Error loading image:', error);
                return `/images/displayCards/empty.png`;
            }
        },
        getCardByPosition(app, position) {
            return app.cards ? app.cards.find(card => card.position === position) : null;
        },
        goHome() {
            this.$router.push({ name: 'CosmoHome' });
        }
    }


};
</script>

<style scoped>
.dropdown {
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    padding: 5px 10px;
    background-color: transparent;
    /* No background */
    color: white;
    /* Text color */
    border: 1px solid white;
    /* White border */
    border-radius: 5px;
    /* Rounded corners */
    cursor: pointer;
    /* Pointer cursor on hover */
    font-size: 16px;
    /* Text size */
    -webkit-appearance: none;
    /* For some browsers to remove default styling */
    appearance: none;

    margin-top: 10px;
    margin-bottom: 20px;
}

.dropdown:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.container option {
    background-color: #262626;
    /* No background */
    color: white;
    /* Text color */
}

.v3-app-card {
    background: #333;
    padding: 10px;
    border-radius: 20px;
    width: 100%;
    box-sizing: border-box;
    transition: border 0.3s ease;
    /* Smooth transition for border change */
}

.v3-app-card.selected {
    border: 1px solid #ffffff;
    /* White border when selected */
}

.v3-app-container p {
    margin-bottom: 10px !important;
    margin-top: 0px;
    text-align: center;
    color: white;
}

.v3-app-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    margin: 0 auto;
}

.v3-app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.frame-container {
    width: 100%;
    max-width: 200px;
    height: 0;
    padding-bottom: calc((480 / 280) * 100%);
    position: relative;
}

.frame.small-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.frame-row {
    width: 100%;
    height: 116px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.double-row {
    height: 232px;
}

.no-row {
    height: 0px;
}

.frame-image.small-frame-image {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.double-image {
    height: 232px;
}

.confirmation p {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 20px;
}


.app-card.selected {
    border: 1px solid #ffffff;
    /* Highlight selected apps */
}

.app-card .order-number {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #4CAF50;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.text {
    margin-bottom: 0px;
    margin-top: 50px;
}

.container p {
    margin-bottom: 50px;
}

.setup-confirmation p {
    margin-bottom: 10px;
}
</style>