<template>
    <div class="container">
        <div class="header">
            <button v-if="!confirmationShown" class="back-button" @click="goBack"><i
                    class="fas fa-arrow-left"></i></button>
            <h1>{{ confirmationShown ? $t('scheduleConfirmedTitle') : $t('scheduleAppsTitle') }}</h1>
        </div>
        <p>{{ confirmationShown ? $t('scheduleConfirmed') : $t('chooseAndScheduleAllApps') }}</p>
        <button v-if="confirmationShown" class="button" @click="goHome">{{ $t('homeButton') }}</button>

        <div v-if="!confirmationShown" class="v3-app-grid">
            <div v-for="(app, index) in apps" :key="index" class="v3-app-container">
                <div :class="['v3-app-card', { 'selected': app.selected }]">
                    <p>{{ app.name }}</p>
                    <div class="frame-container" @click="toggleAppSelection(app)">
                        <div class="frame small-frame">
                            <div v-for="position in [0, 1, 2]" :key="position" :class="[
                                'frame-row',
                                { 'double-row': getCardByPosition(app, position)?.size === 2 },
                                { 'no-row': position > 0 && getCardByPosition(app, position - 1)?.size === 2 }
                            ]">
                                <img v-if="!getCardByPosition(app, position) && position > 0 && getCardByPosition(app, position - 1)?.size !== 2"
                                    :src="getCardImage({ imagePath: 'empty.png' })"
                                    class="frame-image small-frame-image" alt="Placeholder Image">
                                <img v-else-if="getCardByPosition(app, position)"
                                    :src="getCardImage(getCardByPosition(app, position))"
                                    class="frame-image small-frame-image"
                                    :class="{ 'double-image': getCardByPosition(app, position)?.size === 2 }"
                                    alt="Card Image">
                            </div>
                        </div>
                    </div>

                    <div class="time-selection" v-if="app.selected">
                        <div class="time-row">
                            <span>{{ $t('start') }}</span>
                            <select class="dropdown" v-model="app.startTime" :disabled="isFirstSelected(app)">
                                <option v-for="hour in hours" :key="`start-${hour}`" :value="formatHour(hour)">
                                    {{ formatHour(hour) }}
                                </option>
                            </select>
                        </div>
                        <div class="time-row">
                            <span>{{ $t('end') }}</span>
                            <select class="dropdown" v-model="app.endTime">
                                <option v-for="hour in hours" :key="`end-${hour}`" :value="formatHour(hour)">
                                    {{ formatHour(hour) }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button v-if="selectedApps.length > 0 && !confirmationShown" class="button" @click="confirmSelection">
            {{ $t('confirmButton') }}
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedApps: [],
            selectedInterval: 5, // Default interval
            confirmationShown: false,
        };
    },
    computed: {
        apps() {
            const appTranslationKeys = {
                1: 'appNameClock',
                2: 'appNameTempIn',
                3: 'appNameTempOut',
                4: 'appNameTimeMoon',
                5: 'appNameTimeHoro',
                6: 'appNameTimeStocks',
                7: 'appNameWeather',
                8: 'appNameForecast',
                9: 'appNameMoon',
                10: 'appNameHoroscope',
                11: 'appNameStocks',
            };

            return this.$store.state.authUser.apps.map(app => {
                return {
                    ...app,
                    cards: app.customApp
                        ? this.$store.state.authUser.customApps.find(customApp => customApp.clockJson.id === app.id)?.cards
                        : this.$store.state.authUser.defaultApps.find(defaultApp => defaultApp.clockJson.id === app.id)?.cards,
                    name: appTranslationKeys[app.id] ? this.$t(appTranslationKeys[app.id]) : app.name || `App ${app.id}`,
                };
            });
        },
        hours() {
            return Array.from({ length: 24 }, (_, i) => i);
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        toggleAppSelection(app) {
            if (app.selected && this.selectedApps.length === 5) {
                app.selected = false;
                this.selectedApps = this.apps.filter(app => app.selected);
            } else if (this.selectedApps.length < 10) {
                if (!app.selected && this.selectedApps.length === 0) {
                    app.selected = true;
                    app.startTime = '00:00';
                } else {
                    app.selected = !app.selected;
                }
                this.selectedApps = this.apps.filter(app => app.selected);
            } else {
                alert('You can only select up to 10 apps');
            }
        },
        isFirstSelected(app) {
            return this.selectedApps.length > 0 && this.selectedApps[0].name === app.name && app.startTime === '00:00';
        },
        confirmSelection() {
            const timeToMinutes = (time) => {
                const [hours, minutes] = time.split(':').map(Number);
                return hours * 60 + minutes;
            };

            let coveredMinutes = new Array(1440).fill(false);

            for (const app of this.selectedApps) {
                let startTimeInMinutes = timeToMinutes(app.startTime);
                let endTimeInMinutes = timeToMinutes(app.endTime);

                if (endTimeInMinutes <= startTimeInMinutes) {
                    endTimeInMinutes += 1440;
                }

                for (let minute = startTimeInMinutes; minute < endTimeInMinutes; minute++) {
                    if (minute < 1440) {
                        coveredMinutes[minute] = true;
                    } else {
                        coveredMinutes[minute - 1440] = true;
                    }
                }
            }

            const fullDayCovered = coveredMinutes.every(covered => covered);

            if (!fullDayCovered) {
                alert('There is an overlap or a gap in your schedule.');
                return;
            }

            if (fullDayCovered) {
                const postData = {
                    clockName: this.$store.state.authUser.name,
                    appSchedules: this.selectedApps.map(app => ({
                        appId: app.id,
                        startHour: parseInt(app.startTime.split(':')[0]),
                        endHour: parseInt(app.endTime.split(':')[0]),
                    })),
                    updateInterval: parseInt(this.selectedInterval),
                };

                const url = `${process.env.VUE_APP_API_BASE_URL}/scheduleApps`;
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                })
                    .then(response => {
                        if (!response.ok) {
                            alert('Connection error, please try again.');
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(() => {
                        this.confirmationShown = true;
                    })
                    .catch(error => {
                        console.error('Error updating app display settings:', error);
                    });
            }
        },
        goHome() {
            this.$router.push({ name: 'CosmoHome' });
        },
        formatHour(hour) {
            return `${hour.toString().padStart(2, '0')}:00`;
        },
        getCardImage(card) {
            if (!card || !card.imagePath) {
                return `/images/displayCards/empty.png`;
            }
            try {
                return `/images/displayCards/${card.imagePath}`;
            } catch (error) {
                console.error('Error loading image:', error);
                return `/images/displayCards/empty.png`;
            }
        },
        getCardByPosition(app, position) {
            return app.cards ? app.cards.find(card => card.position === position) : null;
        }
    },
};
</script>

<style scoped>
.v3-app-card {
    background: #333;
    padding: 10px;
    border-radius: 20px;
    width: 100%;
    box-sizing: border-box;
    transition: border 0.3s ease;
}

.v3-app-card.selected {
    border: 1px solid #ffffff;
}

.v3-app-container p {
    margin-bottom: 10px;
    margin-top: 0px;
    text-align: center;
    color: white;
}

.v3-app-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.v3-app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.frame-container {
    width: 100%;
    max-width: 200px;
    height: 0;
    padding-bottom: calc((480 / 280) * 100%);
    position: relative;
}

.frame.small-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.frame-row {
    width: 100%;
    height: 116px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.double-row {
    height: 232px;
}

.no-row {
    height: 0px;
}

.frame-image.small-frame-image {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.double-image {
    height: 232px;
}

.time-selection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    flex-grow: 0;
    transition: max-height 0.3s ease;
}

.v3-app-card.selected .time-selection {
    visibility: visible;
    flex-grow: 1;
}

.time-row {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    margin-top: 5px;
}

.time-row span {
    width: 40px;
    display: inline-block;
    text-align: left;
}

.dropdown {
    width: calc(100% - 50px);
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    -webkit-appearance: none;
    appearance: none;
}

.dropdown:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.container option {
    background-color: #262626;
    color: white;
}
</style>
