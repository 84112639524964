<template>
  <div class="login-container">
    <h1>{{ $t('welcomeTitle') }}</h1>
    <p>{{ $t('connectCosmo') }}</p>
    <input type="text" :placeholder="$t('cosmoNameOrIDPlaceholder')" :value="cosmoId"
      @input="updateCosmoId($event.target.value)" />
    <div>
      <button class="button button-connect" @click="connect">
        {{ isLoading ? $t('loadingButton') : $t('connectButton') }}
      </button>
      <span class="button" @click="redirectToSetup">{{ $t('setup') }}</span>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      cosmoId: '',
      connectedCosmo: null, // To store the connected Cosmo document
      isLoading: false, // Add this property to track loading state
    };
  },
  created() {
    // Attempt to retrieve the clock name from localStorage
    const savedClockName = localStorage.getItem('clockName');
    // console.log(savedClockName)
    if (savedClockName) {
      // If a clock name is found, prefill the cosmoId with this value
      this.cosmoId = savedClockName;
    }

    const savedLanguage = localStorage.getItem('language');

    if (savedLanguage && this.$i18n.availableLocales.includes(savedLanguage)) {
      // Set the language using Vue I18n's $i18n instance
      this.$i18n.locale = savedLanguage;
    }
  },
  methods: {
    updateCosmoId(value) {
      this.cosmoId = value;
    },
    redirectToSetup() {
      this.$router.push({ name: 'CosmoSetup' });
    },
    async connect() {

      if (this.cosmoId === "") return;
      this.isLoading = true;
      // console.log("Connecting to Cosmo Clock");
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/login?identifier=${this.cosmoId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to connect. Please try again.');
        }
        const data = await response.json();
        // console.log(data)
        // console.log("Connected successfully");
        this.connectedCosmo = data; // Store the received Cosmo document
        this.$store.dispatch('authenticateUser', data);
        localStorage.setItem('clockName', this.cosmoId);

        const savedLanguage = localStorage.getItem('language');

        if (!savedLanguage && data.language && this.$i18n.availableLocales.includes(data.language)) {
          // Set the language using Vue I18n's $i18n instance
          this.$i18n.locale = data.language;
          localStorage.setItem('language', data.language);
        }

        // Check if the data includes a 'location' field with both 'lat' and 'lon' subfields
        if (data.location && typeof data.location.lat === "number" && typeof data.location.lon === "number") {
          this.$router.push('/home'); // Navigate to CosmoHome on success
        } else {
          // Redirect to the location route to set/update the location
          this.$router.push('/location');
        }
      } catch (error) {
        console.error(error);
        alert(error.message); // Show error message
      } finally {
        this.isLoading = false; // Reset loading state
      }
    },

  },
};
</script>


<style scoped>
.button-connect {
  margin-right: 10px;
  background:white;
  border:none;
  color:#333;
}
.login-container h1 {
  margin-bottom: 0;
  /* Reduce or remove the bottom margin of the h1 */
}

.login-container p {
  margin-top: 10px;
  /* Reduce or remove the top margin of the p */
  /* Additional styling for the paragraph to match your design, if needed */
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Ensure overall content is centered */
  color: white;
  text-align: center;
  margin-top: 0%;
}

input[type="text"] {
  margin: 20px 0;
  padding: 10px;
  border: 1px solid white;
  /* Set border color to white */
  border-radius: 20px;
  /* Increase border-radius to 20 */
  background-color: transparent;
  /* Make background transparent */
  color: white;
  /* Ensure text color is white */
  font-size: 16px;
}

input[type="text"]::placeholder {
  font-size: 16px;
  /* This sets the font size for the placeholder text */
}

.question-mark {
  cursor: pointer;
  color: white;
  /* Adjusted the color to white as per your requirement */
  margin-left: 10px;
  font-size: 16px;
  border: 1px solid rgb(104, 104, 104);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}
</style>