<template>
    <div class="container">
        <div class="title-container">
            <button class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
            <h1>{{ $t('cosmoShopTitle') }}</h1>
        </div>
        <p>{{ $t('helpMessage') }}</p>
        <a :href="'mailto:phis.studio@gmail.com'" class="email-link">phis.studio@gmail.com</a>
    </div>
</template>

<script>
export default {
    methods: {
        goBack() {
            this.$router.go(-1); // Go back to the previous page
        },
        goHome() {
            this.$router.push({ name: 'CosmoHome' });
        },
    },
};
</script>

<style scoped>

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.email-link {
    color: white; /* Set the color to white */
    font-weight: bold; /* Make the text bold */
}

</style>