import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './i18n'; // Import the i18n instance
import './assets/main.css';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(i18n); // Use i18n instance

app.mount('#app');
