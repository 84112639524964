// store/index.js

import { createStore } from 'vuex';

export default createStore({
    state: {
        isAuthenticated: false,
        loginResponse: {},
        selectedCardPositions: [], 
    },
    mutations: {
        setAuthentication(state, status) {
            state.isAuthenticated = status;
        },
        setLoginResponse(state, data) {
            state.loginResponse = data;
        },
        setAuthUser(state, userData) {
            state.authUser = userData;
        },
        clearAuthUser(state) {
            state.authUser = null;
        },
        updateSettings(state, newSettings) {
            // Assuming you have a `settings` field in your state
            state.authUser.settings = newSettings;
        },
        updateAppSettings(state, appSettings) {
            state.authUser.appSettings = appSettings;
        },
        setAuthUserEmail(state, email) {
            if (state.authUser) {
                state.authUser.email = email;
            }
        },
        setSelectedCardPositions(state, payload) {
            state.selectedCardPositions = payload;
        },
    },
    actions: {
        authenticateUser({ commit }, data) {
            commit('setAuthentication', true);
            commit('setLoginResponse', data);
            commit('setAuthUser', data);
        },
        updateSelectedCardPositions({ commit }, selectedCardPositions) {
            commit('setSelectedCardPositions', selectedCardPositions);
        },
    },
    getters: {
        getSelectedCardPositions(state) {
            return state.selectedCardPositions;
        },
    },
});
