<template>
    <div class="container">
        <div class="title-container">
            <button class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
            <h1>{{ $t('locationTitle') }}</h1>
        </div>
        <p v-if="!confirmedLocation">
            {{ !locations.length ? $t('instruction') : $t('chooseYourLocation') }}
        </p>

        <div v-if="!confirmedLocation && !locations.length" class="search-container">
            <input type="text" :placeholder="$t('searchLocationPlaceholder')" v-model="searchQuery" />
            <button class="button" @click="searchLocation" :disabled="isSearching">
                {{ isSearching ? $t('searchingButton') : $t('searchButton') }}
            </button>
        </div>

        <div v-if="!confirmedLocation && locations.length" class="results">
            <ul>
                <li v-for="(location, index) in locations" :key="index" @click="setCurrentLocation(location)"
                    :class="{ 'highlighted-location': location === currentLocation }">
                    {{ location.formatted }}
                </li>
            </ul>
        </div>

        <div v-if="!confirmedLocation && locations.length" class="action-buttons">
            <button class="button" @click="confirmLocation" :disabled="isConfirming">
                {{ isConfirming ? $t('confirmingButton') : $t('confirmButton') }}
            </button>
        </div>

        <div v-if="confirmedLocation">
            <p>{{ $t(isInitialSetup ? 'locationFirstConfirmed' : 'locationConfirmed') }}</p>
            <button class="button" @click="goHome">{{ $t('homeButton') }}</button>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            searchQuery: '',
            locations: [],
            currentLocation: '',
            confirmedLocation: '',
            instruction: 'Set your location for Cosmo to get temperature, weather, and more.',
            isSearching: false,
            isConfirming: false,
        };
    },
    computed: {
        isInitialSetup() {
            const location = this.$store.state.authUser.location;
            return !(location && typeof location.lat === "number" && typeof location.lon === "number");
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1); // Go back to the previous page
        },
        async searchLocation() {
            if (!this.searchQuery.trim()) {
                alert("Please enter a search query.");
                this.isSearching = false;
                return;
            }

            this.isSearching = true;
            const url = `${process.env.VUE_APP_API_BASE_URL}/searchLocation?location=${encodeURIComponent(this.searchQuery)}`; // Adjust the domain/port as needed

            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Failed to fetch locations');
                }
                const data = await response.json();
                // Format the location data for display
                this.locations = data.map(loc => ({
                    name: loc.name,
                    formatted: `${loc.name}${loc.state ? ', ' + loc.state : ''}, ${loc.country}`,
                    country: loc.country,
                    state: loc.state || 'N/A',
                    lon: loc.lon,
                    lat: loc.lat
                }));
            } catch (error) {
                console.error('Error searching for locations:', error);
                alert('Error searching for locations. Please try again.');
            } finally {
                this.isSearching = false;
            }
        },
        setCurrentLocation(location) {
            this.currentLocation = location;
        },
        async confirmLocation() {
            if (!this.currentLocation) {
                alert("Please select a location first.");
                return;
            }

            this.isConfirming = true;
            // Use your actual API base URL and adjust the endpoint as needed
            const url = `${process.env.VUE_APP_API_BASE_URL}/setLocation`;

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        clockName: this.$store.state.authUser.name,
                        lat: this.currentLocation.lat,
                        lon: this.currentLocation.lon,
                        name: this.currentLocation.name,
                    }),
                });

                if (!response.ok) {
                    throw new Error(`Failed to set location (${response.status})`);
                }

                // Assuming the API returns some success message or data
                await response.json();
                // console.log('Location set successfully');
                if (this.isInitialSetup) {
                    this.confirmedLocation = "Look at your clock; it should be updated within a minute.";
                } else {
                    this.confirmedLocation = this.currentLocation.formatted; // Use formatted string for display
                }
            } catch (error) {
                console.error('Error setting location:', error.message || error);
                alert('Error setting location. Please try again.');
            }
            finally {
                this.reset(); // Reset after attempting to confirm, regardless of success or failure
                this.isConfirming = false;
            }
        },
        goHome() {
            this.$router.push('/home');
        },
        reset() {
            this.locations = [];
            this.currentLocation = '';
        },
    },
};
</script>


<style scoped>
.highlighted-location {
    background-color: rgba(255, 255, 255, 0.3);
    /* Lighter or different background for highlighted location */
}

li:hover,
.highlighted-location:hover {
    background-color: rgba(255, 255, 255, 0.2);
    /* Keep hover effect consistent */
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Ensure overall content is centered */
    color: white;
    text-align: center;
    margin-top: 0%;
}

.search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input[type="text"] {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid white;
    /* Set border color to white */
    border-radius: 20px;
    /* Increase border-radius to 20 */
    background-color: transparent;
    /* Make background transparent */
    color: white;
    /* Ensure text color is white */
    font-size: 16px;
}

input[type="text"]::placeholder {
    font-size: 16px;
    /* This sets the font size for the placeholder text */
}

.results {
    margin-top: 10px;
    max-height: 200px;
    overflow-y: scroll;
    width: 80%;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

li:hover {
    background-color: rgba(255, 255, 255, 0.2);
}


.container h1 {
    margin: 0;
    padding: 0;
}



.container p {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 20px;
    /* Reduce or remove the top margin of the p */
    /* Additional styling for the paragraph to match your design, if needed */
}

.action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.button {
    margin-bottom: 10px;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}


/* Update the h1 margin to align title properly if needed */
.settings-container h1 {
    margin: 0;
    padding: 0;
}
</style>
