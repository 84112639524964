import { createI18n } from 'vue-i18n'; // Note the change here for Vue 3
import en from './locales/en.json';
import de from './locales/de.json';
import it from './locales/it.json';
import fr from './locales/fr.json';

const messages = {
    en,
    de,
    it,
    fr,
  };
  
  // Create i18n instance with options
  const i18n = createI18n({
    legacy: false, // Vue 3 recommendation for new projects
    locale: 'en', // set localede
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
  });
  
  export default i18n;
