<template>
    <div class="container">
        <div class="header">
            <button class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
            <h1>{{ $t('appsHeader') }}</h1>
        </div>
        <p>{{ $t('welcomeApps') }}</p>
        <div class="app-cards">
            <div v-if="clockId.startsWith('V3')" class="card" @click="createApp">
                <i class="fas fa-plus-circle card-icon"></i>
                <h3>{{ $t('createAppTitle') }}</h3>
                <p>{{ $t('createAppDescription') }}</p>
            </div>
            <div class="card" @click="displayApps">
                <i class="fas fa-clock card-icon"></i>
                <h3>{{ $t('displayAppTitle') }}</h3>
                <p>{{ $t('displayAppDescription') }}</p>
            </div>
            <div class="card" @click="scheduleApps">
                <i class="fas fa-calendar card-icon"></i>
                <h3>{{ $t('scheduleAppsTitle') }}</h3>
                <p>{{ $t('scheduleAppsDescription') }}</p>
            </div>
            <div class="card" @click="rotateApps">
                <i class="fas fa-sync-alt card-icon"></i>
                <h3>{{ $t('appRotationTitle') }}</h3>
                <p>{{ $t('appRotationDescription') }}</p>
            </div>
            <div class="card" @click="customizeApps">
                <i class="fas fa-cogs card-icon"></i>
                <h3>{{ $t('customizeAppsTitle') }}</h3>
                <p>{{ $t('customizeAppsDescription') }}</p>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    computed: {
        clockId() {
            return this.$store.state.authUser.clockId; // Adjust this based on how you retrieve the clockId
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1); // Go back to the previous page
        },
        createApp() {
            this.$router.push({ name: 'CosmoBuildApp' });
        },
        displayApps() {
            this.$router.push({ name: 'CosmoDisplayApps' });
        },
        rotateApps() {
            if (this.clockId.startsWith("V3")) {
                this.$router.push({ name: 'CosmoRotateAllApps' });
            } else {
                this.$router.push({ name: 'CosmoRotateApps' });
            }
        },
        customizeApps() {
            this.$router.push({ name: 'CosmoCustomizeApps' });
        },
        scheduleApps() {
            if (this.clockId.startsWith("V3")) {
                this.$router.push({ name: 'CosmoScheduleAllApps' });

            } else {
                this.$router.push({ name: 'CosmoScheduleApps' });

            }
        }
    }
};
</script>

<style scoped>
.app-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
}


.card {
    background: #333;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 100%;
    /* This makes the card fill the container */
    max-width: 400px;
    /* Keeps the card from exceeding 400px */
    box-sizing: border-box;
    /* Ensures padding is included in the total width and height */
    transition: transform 0.3s ease;
}


.card-icon {
    font-size: 30px;
    margin-bottom: 10px;
}

.card h3 {
    margin: 0;
}

.card p {
    text-align: center;
    margin-bottom: 0px;
}

.card:hover {
    transform: scale(1.05);
    /* Slight scale effect on hover */
}
</style>