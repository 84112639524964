import { createRouter, createWebHistory } from 'vue-router';
import CosmoLogin from '@/components/CosmoLogin.vue';
import CosmoHome from '@/components/CosmoHome.vue';
import CosmoLocation from '@/components/CosmoLocation.vue';
import CosmoSettings from '@/components/CosmoSettings.vue';
import CosmoNewSettings from '@/components/CosmoNewSettings.vue';
import CosmoSetup from '@/components/CosmoSetup.vue';
import CosmoShop from '@/components/CosmoShop.vue';
import CosmoApps from '@/components/CosmoApps.vue';
import CosmoDisplayApps from '@/components/CosmoDisplayApps.vue';
import CosmoRotateApps from '@/components/CosmoRotateApps.vue';
import CosmoCustomizeApps from '@/components/CosmoCustomizeApps.vue';
import CosmoCustomizeApp from '@/components/CosmoCustomizeApp.vue';
import CosmoCustomizeNewApp from '@/components/CosmoCustomizeNewApp.vue';
import CosmoScheduleApps from '@/components/CosmoScheduleApps.vue';
import CosmoSetTime from '@/components/CosmoSetTime.vue';
import CosmoBuildApp from '@/components/CosmoBuildApp.vue';
import CosmoBuildAppSettings from '@/components/CosmoBuildAppSettings.vue';
import CosmoRotateAllApps from '@/components/CosmoRotateAllApps.vue';
import CosmoScheduleAllApps from '@/components/CosmoScheduleAllApps.vue';
import CosmoAlarms from '@/components/CosmoAlarms.vue';

import store from '../store';

const routes = [
    { path: '/login', name: 'CosmoLogin', component: CosmoLogin },
    { path: '/', name: 'home', component: CosmoHome, meta: { requiresAuth: true } },
    { path: '/home', name: 'CosmoHome', component: CosmoHome, meta: { requiresAuth: true } },
    { path: '/location', name: 'CosmoLocation', component: CosmoLocation, meta: { requiresAuth: true } },
    { path: '/settings', name: 'CosmoSettings', component: CosmoSettings, meta: { requiresAuth: true } },
    { path: '/generalsettings', name: 'CosmoNewSettings', component: CosmoNewSettings, meta: { requiresAuth: true } },
    { path: '/setup', name: 'CosmoSetup', component: CosmoSetup, meta: { requiresAuth: false } },
    { path: '/shop', name: 'CosmoShop', component: CosmoShop, meta: { requiresAuth: true } },
    { path: '/apps', name: 'CosmoApps', component: CosmoApps, meta: { requiresAuth: true } },
    { path: '/displayApps', name: 'CosmoDisplayApps', component: CosmoDisplayApps, meta: { requiresAuth: true } },
    { path: '/rotateApps', name: 'CosmoRotateApps', component: CosmoRotateApps, meta: { requiresAuth: true } },
    { path: '/rotateAllApps', name: 'CosmoRotateAllApps', component: CosmoRotateAllApps, meta: { requiresAuth: true } },
    { path: '/customizeApps', name: 'CosmoCustomizeApps', component: CosmoCustomizeApps, meta: { requiresAuth: true } },
    { path: '/customizeApp', name: 'CosmoCustomizeApp', component: CosmoCustomizeApp, meta: { requiresAuth: true } },
    { path: '/customizeNewApp', name: 'CosmoCustomizeNewApp', component: CosmoCustomizeNewApp, meta: { requiresAuth: true } },
    { path: '/scheduleApps', name: 'CosmoScheduleApps', component: CosmoScheduleApps, meta: { requiresAuth: true } },
    { path: '/scheduleAllApps', name: 'CosmoScheduleAllApps', component: CosmoScheduleAllApps, meta: { requiresAuth: true } },
    { path: '/time', name: 'CosmoSetTime', component: CosmoSetTime, meta: { requiresAuth: true } },
    { path: '/buildApp', name: 'CosmoBuildApp', component: CosmoBuildApp, meta: { requiresAuth: true } },
    { path: '/buildAppSettings', name: 'CosmoBuildAppSettings', component: CosmoBuildAppSettings, meta: { requiresAuth: true } },
    { path: '/alarms', name: 'CosmoAlarms', component: CosmoAlarms, meta: { requiresAuth: true } },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !store.state.isAuthenticated) {
        next({ path: '/login' });
    } else if (to.matched.some(record => !record.meta.requiresAuth)) {
        // Skip authentication check for routes that don't require authentication
        next();
    } else {
        next();
    }
});


export default router;